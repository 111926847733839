import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import React from 'react';
import { Button } from '@chakra-ui/react';

import { formatWalletAddress } from '@app/helpers/format';

import s from './CustomConnectWalletButton.module.scss';

const ConnectedWalletButton = ({ address }: { address: string }) => (
  <Button
    h={{
      base: '36px',
      md: '48px'
    }}
    border="1px"
    borderColor="rgba(0,0,0,0.12)"
  >
    {formatWalletAddress(address)}
  </Button>
);

export const CustomConnectWalletButton = () => {
  const address = useAddress();

  return (
    <ConnectWallet
      theme="dark"
      modalSize="wide"
      className={s.root}
      detailsBtn={() =>
        address ? <ConnectedWalletButton address={address} /> : <div />
      }
      switchToActiveChain
    />
  );
};
