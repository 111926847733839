export const META_TAGS_DEFAULT_CONFIG = {
  twitterCardType: 'summary_large_image',
  twitterCreator: '@name',
  twitterImageAlt: '',
  twitterCite: '@name',
  title: 'Superchain One',
  description: '',
  url: '',
  image: ''
};
