import { Flex } from '@chakra-ui/react';

import S1White from './s1White.svg';

export const S1Logo = () => (
  <Flex
    w={'auto'}
    h={'100%'}
    aspectRatio={1}
    align="center"
    justify="center"
    bg="brandRed"
    borderRadius="lg"
    p={{
      base: 1,
      md: 2
    }}
  >
    <S1White />
  </Flex>
);
