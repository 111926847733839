'use client';

import { Flex, Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Red4 from '@app/assets/images/bg/red4.svg';

import Metadata, { MetadataProps } from '@app/components/Metadata';
import backgroundPattern from '@app/assets/images/bg/backgroundPattern.png';
import { useUser } from '@thirdweb-dev/react';
import { fetchUser, createUser } from '@app/api/profile';
import { queryClient } from '@app/context/AppContextWrapper';

interface MainLayoutProps {
  children: React.ReactNode;
  metadata?: MetadataProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, metadata }) => {
  const { user } = useUser();

  const publicKey = user?.address;

  useEffect(() => {
    if (publicKey) {
      (async () => {
        const user = await fetchUser();

        if (!user?.publicKey) {
          await createUser(publicKey);
          queryClient.invalidateQueries({
            queryKey: ['user', publicKey]
          });
        }
      })();
    }
  }, [publicKey]);

  return (
    <Flex
      w="100%"
      flexDir="column"
      backgroundImage={backgroundPattern.src}
      position="relative"
    >
      <Metadata {...metadata} />
      {children}
      <Box
        position="absolute"
        bottom={0}
        transform="translate(-50%)"
        filter="blur(151.9949951171875px)"
        zIndex={0}
      >
        <Red4 />
      </Box>
    </Flex>
  );
};

export default MainLayout;
