import { Flex, Spacer, Text, useToast } from '@chakra-ui/react';
import { Link } from '@chakra-ui/next-js';
import _ from 'lodash';

import S1LogoRed from '@app/assets/images/logo/s1LogoRed.svg';
import { FaXTwitter, FaDiscord } from 'react-icons/fa6';

export const Footer = () => {
  const toast = useToast();

  const comingSoon = _.throttle(
    () =>
      toast({
        title: 'Coming Soon',
        status: 'info'
      }),
    5000
  );

  return (
    <Flex w="100%" direction="column" align="center" zIndex={2}>
      <Flex
        w="100%"
        direction={{
          base: 'column-reverse',
          md: 'row'
        }}
        position="relative"
        overflow="hidden"
        px={{
          base: 2,
          md: '100px'
        }}
      >
        <Flex
          flex={1}
          flexDir="column"
          align="start"
          mt={{
            base: 10,
            md: 0
          }}
          py={{ base: '10px', md: '90px' }}
          px={4}
        >
          <S1LogoRed />
          <Text mt={4} fontWeight="bold" fontSize="16px">
            Ultimate launch vehicle for all superchains
          </Text>
          <Spacer />
          <Flex
            dir="row"
            align="center"
            justify="start"
            gap={4}
            mt={{
              base: 5,
              md: 0
            }}
          >
            <Link href="https://twitter.com/SuperchainOne" target="_blank">
              <Flex
                bg="black"
                w="30px"
                h="30px"
                align="center"
                justify="center"
                borderRadius="50%"
              >
                <FaXTwitter size={20} color="white" />
              </Flex>
            </Link>
            <Link href="https://discord.gg/JBH4pHBKHJ" target="_blank">
              <Flex
                bg="black"
                w="30px"
                h="30px"
                align="center"
                justify="center"
                borderRadius="50%"
              >
                <FaDiscord size={20} color="white" />
              </Flex>
            </Link>
          </Flex>
        </Flex>
        <Flex
          flex={1}
          w="100%"
          fontSize="16px"
          fontWeight="bold"
          textColor="rgba(0,0,0,0.5)"
          px={4}
          py={{ base: '10px', md: '90px' }}
        >
          <Flex flexDir="column" align={{ base: 'start', md: 'end' }} flex={1}>
            <Flex flexDir="column" align="start" gap={{ base: 4, md: 8 }}>
              <Text fontSize="20px" color="black">
                Documentation
              </Text>
              <Text onClick={comingSoon} as="button" fontWeight="bold">
                Apply for launch
              </Text>
              <Text onClick={comingSoon} as="button" fontWeight="bold">
                Documentation
              </Text>
              <Text onClick={comingSoon} as="button" fontWeight="bold">
                Terms & Privacy
              </Text>
            </Flex>
          </Flex>
          <Flex flexDir="column" align={{ base: 'start', md: 'end' }} flex={1}>
            <Flex flexDir="column" align="start" gap={{ base: 4, md: 8 }}>
              <Text fontSize="20px" color="black">
                Resources
              </Text>
              <Text onClick={comingSoon} as="button" fontWeight="bold">
                Help center
              </Text>
              <Link
                href="https://mirror.xyz/0xa0C225a98aBC06db7c1cC6d97D1733c047F3d911"
                target="_blank"
              >
                Blog
              </Link>
              <Text onClick={comingSoon} as="button" fontWeight="bold">
                Media Kit
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
