import {
  Container,
  Flex,
  Spacer,
  Text,
  Badge,
  Link,
  Box
} from '@chakra-ui/react';
import _ from 'lodash';

import { S1Logo } from './S1Logo';
import { IconButton } from '@app/components/IconButton';
import { useAddress, useConnectionStatus } from '@thirdweb-dev/react';
import { CustomConnectWalletButton } from '@app/components/CustomConnectWalletButton';
import { useRouter } from 'next/router';

type Props = {
  projectName?: string;
};

const NavigationBar = ({ projectName }: Props) => {
  const router = useRouter();
  const connectionStatus = useConnectionStatus();
  const address = useAddress();

  return (
    <Flex
      w="100%"
      justify="center"
      position="absolute"
      top="0"
      h={{ base: '64px', md: '80px' }}
      zIndex={2}
    >
      <Container
        display={'flex'}
        flexDirection="row"
        alignItems="center"
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
        w={'100%'}
        px={4}
        py={4}
      >
        <Link href="/" h={{ base: '36px', md: '48px' }}>
          <S1Logo />
        </Link>
        {projectName && (
          <Flex align="center" ml={4} h={4}>
            <Box bg="black" border="1px" borderColor="black" h="100%" />
            <Text ml={4} color="brandRed" fontSize="18px" fontWeight="semibold">
              {projectName}
            </Text>
          </Flex>
        )}
        <Spacer />
        <Flex direction="row" align="center" gap={4} h="100%">
          {!projectName && (
            <Flex
              flexDir="column"
              align="end"
              display={{
                base: 'none',
                md: 'flex'
              }}
            >
              <Text fontSize="16px" fontWeight="semibold" opacity={0.5}>
                Apply for Launch
              </Text>
              <Badge>Coming Soon</Badge>
            </Flex>
          )}
          {connectionStatus === 'connected' && (
            <>
              <IconButton
                onClick={() => router.push(`/profile/`)}
                icon="USER"
              />
            </>
          )}
          <CustomConnectWalletButton />
        </Flex>
      </Container>
    </Flex>
  );
};

export default NavigationBar;
