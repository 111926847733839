import { Flex } from '@chakra-ui/react';
import { FaUser, FaBell } from 'react-icons/fa6';

type Props = {
  icon: 'USER' | 'NOTIFICATION';
  onClick: () => void;
};

export const IconButton = ({ icon, onClick }: Props) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      width={'auto'}
      height={'100%'}
      borderRadius="md"
      border="1px"
      borderColor="rgba(0,0,0,0.2)"
      as="button"
      aspectRatio={1}
    >
      {icon === 'USER' ? <FaUser size={20} /> : <FaBell size={20} />}
    </Flex>
  );
};
