import { config } from '@app/config';

import type { ProjectId, ProjectDetails } from '@app/types';

const USDC_MAINNET =
  '0xd988097fb8612cc24eeC14542bC03424c656005f' as `0x{string}`;
const TESTNET_USDC =
  '0x9678DB40aBAC3C5E0530DC4fBf6174BE90F7E833' as `0x{string}`;
const MAINNET_SALE_CONTRACT_ADDRESS =
  '0x68e6c417b6607eA0b406168Cb625299C75b223e8' as `0x{string}`;
const TESTNET_SALE_CONTRACT_ADDRESS =
  '0x482a77676aEb0353a96F4d75076A2fFDF9770503' as `0x{string}`;

const USDC_CONTRACT =
  config.modeEnv === 'TESTNET' ? TESTNET_USDC : USDC_MAINNET;
const SALE_CONTRACT_ADDRESS =
  config.modeEnv === 'TESTNET'
    ? TESTNET_SALE_CONTRACT_ADDRESS
    : MAINNET_SALE_CONTRACT_ADDRESS;

export const projectDetails: Record<ProjectId, ProjectDetails> = {
  IONIC: {
    id: 'IONIC',
    enabled: true,
    name: 'Ionic',
    bgColor: '#71F994',
    accentColor: 'black',
    slideDeck: 'ionic.pdf',
    tagLine:
      '#1 Money Market on the Mode Network for ultimate capital efficiency.',
    description:
      'Absolute capital efficiency for the Mode Network DeFi ecosystem.\nAll assets - One intuitive UI.',
    longDescription:
      'Ionic is a decentralised non-custodial money market protocol, supported by a comprehensive security monitoring and failsafe systems. Ionic gives users the complete control over their funds by providing best interest rates on Mode Network. With meticulously designed tokenonomics model, Ionic aims for robust and long-lasting growth, cultivating exemplary conduct among all participants for the collective benefit of every stakeholder',
    details: [
      {
        heading: 'Highlights',
        content: [
          [
            'Ionic is the #1 App on Mode Network with +141M TVL and 80k users.',
            'Ionic enables users to lend, borrow, leverage and earn on a variety of assets safely and efficiently.',
            'Ionic will expand to the Optimism Superchain and take advantage of the native interoperability mechanisms.',
            'Ionic is led by veteran DeFi builders from Connext, Li.Fi, Polygon, etc. and backed by a highly connected network of angels, whales, and funds.'
          ]
        ]
      },
      {
        heading: 'Product',
        subHeading:
          'Ionic: The Modular, Extensible Money Market for the Superchain.',
        content: [
          'Ionic is the premier money market on Mode Network. Ionic provides a familiar and battle-tested interface to unlock collateral against crypto assets. Ionic had a successful deployment on Mode Network and quickly became the #1 app on Mode by TVL due to its attractive markets, easy-to-use interface, and innovative points program. Ionic currently supports all the major blue chip crypto assets on Mode, including the variety of liquid restaked tokens (LRTs) on the network. Ionic is working with leading risk management firms to constantly analyze market configurations and onboard new assets as soon as they are available on the network.',
          'Phase 2 of the Ionic roadmap is to expand to other select OP stack chains, boosting our brand and becoming one of the defacto lending players in the crypto ecosystem.',
          'Phase 3 of the Ionic roadmap is to truly expand into the Superchain by becoming an app-specific rollup and take full advantage of the Superchain’s native interoperability mechanisms.',
          'In parallel to this development, Ionic will continue to iterate on user experience with automation and tooling such as a perps protocol, cross-chain zaps, automated position management, yield vaults, and much more.'
        ]
      },
      {
        heading: 'Problem',
        subHeading:
          'Superchain Interoperability Creates Opportunities for Novel DeFi Applications.',
        content: [
          'The Superchain is a greenfield construction of interoperable rollups that are ultimately secured by Ethereum. Many app-specific and generic EVM rollups will be created using the one-click frameworks that are being built today. All of these rollups will immediately have a need to access liquidity and create DeFi opportunities for their users.'
        ]
      },
      {
        heading: 'Solution',
        subHeading: 'Creating the First Superchain DeFi “SuperApp”.',
        content: [
          'Ionic aims to be the first protocol that truly makes use of the Superchain’s native interoperability infrastructure. Ionic will create a true DeFi “SuperApp” which provides an amazing user experience and access to liquidity, yields, and cross-chain opportunities.'
        ]
      },
      {
        heading: 'Business Model',
        subHeading:
          '“Real Yield” + DeFi Opportunities + Novel Rollup Mechanics.',
        content: [
          'Ionic Phase 1 and 2 creates revenue from the money market: Percentage of borrow interest, percentage of liquidations. Ionic will innovate to create additional revenue streams on top of the money market including: a perps protocol, yield vaults, automated strategies.',
          'As Ionic transitions into an app-specific rollup, it will collect additional revenue through sequencer fees and other rollup-specific revenue streams.'
        ]
      },
      {
        heading: 'Market',
        subHeading:
          'Starting with a $95 billion DeFi TVL Market, Expanding to the $450 billion TradFi Lending Market.',
        content: [
          'Money markets are one of the few DeFi applications to truly find product market fit. Ionic plans to multiply from the current $140M market size to become one of the largest apps in all of DeFi. Ionic’s unique modular architecture and battle-tested codebase allows it to become the destination for onboarding RWAs, SMBs, and other TradFi lending opportunities onchain, greatly expanding the TAM.',
          'Ionic’s backers include some of the leading names in DeFi, setting up the perfect strategic framework for the team to execute on the vision.'
        ]
      },
      // {
      //   heading: 'Investors',
      //   content: [
      //     [
      //       'Renzo',
      //       'Mode',
      //       'Connext',
      //       'Zellic',
      //       'EtherFi',
      //       'DCF God',
      //       'Superfluid',
      //       'Hash Consulting',
      //       'Pyth',
      //       'Redstone',
      //       'DCI Capital',
      //       'Dewhales'
      //     ]
      //   ]
      // },
      {
        heading: 'Team',
        subHeading: 'Led By Seasoned DeFi Builders and Veteran Founders.',
        content: [
          'Ionic benefits from crypto-native founders with real-world experience.',
          'Rahul is the Tech Lead of Ionic. He is an entrepreneur and prolific software developer (smart contracts, scalable distributed systems) who co-founded and operated as CTO of Connext for over 6 years since 2017. His experience building and scaling one of the most successful cross-chain interoperability projects gives Ionic a huge edge in building a truly cross-chain money market protocol.',
          'Henri is the Project Lead at Ionic. He is a driven founder and investor active in web3 since 2016. Coming from a Marketing and Business Development background, he has helped various web3 teams go to market and scale their projects from 0 to 1 with the R&D firm 0xpragma he helped co-found in 2017. He has a profound understanding of onchain economies from working with household names such as LiFi, district0x and PlanetIX, spanning from interoperability to GameFi to SocialFi.',
          'The rest of the Ionic team is made up of developers, product marketers, and business developers from projects like Polygon and other successful DeFi protocols.'
        ]
      }
    ],
    ticker: 'ION',
    network: 'MODE',
    tokenAddress: null,
    ownerAddress: null,
    saleAddress: null,
    socialURL: {
      website: 'https://www.ionic.money',
      twitter: 'https://twitter.com/ionicmoney',
      discord: 'https://discord.com/invite/FmgedqR9wn',
      github: 'https://github.com/ionicprotocol',
      telegram: 'https://t.me/ionicmoney'
    },
    saleDetails: {
      startsAt: 1712764800,
      endsAt: 1714053600,
      saleContractAddress: SALE_CONTRACT_ADDRESS,
      saleTokenAddress: USDC_CONTRACT,
      maxRaiseAmount: 300000,
      ticketSize: 500
    }
  },
  KIM: {
    id: 'KIM',
    enabled: true,
    name: 'Kim Exchange',
    bgColor: '#121212',
    accentColor: '#FF4500',
    tagLine: `The native DEX of Mode, the liquidity hub of the Optimism Superchain.`,
    details: [
      {
        heading: 'Highlights',
        content: [
          [
            'KIM Exchange is the native dex on Mode with over $20m TVL and $80M cumulative volume',
            'KIM Exchange is a modular OP Superchain DeFi and liquidity hub',
            'KIM Exchange is the most incentivizing dex on Mode, with yield boosting plugins',
            'KIM Exchange uses a mathematical formula to price assets, eliminating the need for order books'
          ]
        ]
      },
      {
        heading: 'Product',
        content: [
          `KIM Exchange is a modular decentralized exchange (DEX) protocol that uses a mathematical formula to price assets, facilitating trading without needing a traditional order book.  Dual Liquidity Type Kim's AMM is based on custom concentrated liquidity pools with modular plugins (hooks).  The Kim AMM is the core of our ecosystem-oriented approach, designed with the following in mind:`,
          [
            'Modular, flexible, and customizable',
            'Optimized trading efficiency',
            'High reward mechanics'
          ],
          'KIM Exchange is modular, which features customizable hooks and incentivized pools.',
          `Phase 2 of KIM Exchange's roadmap is to expand and onboard Base and Optimism, which will increase volume and adopt communities.`,
          `Phase 3 of KIM Exchange's roadmap is to incorporate data availability (DA) layer and security through multi-party computation (MPC).  Restaking can enable a hyperscale Data Availability (DA) layer, with a high DA rate and low cost.  In addition, MPCs will ensure security over users' crypto assets`,
          `Phase 4 of KIM Exchange's roadmap is to apply intents-based trading, which will eliminate the need for high liquidity and enhance user experience.`
        ]
      },
      {
        heading: 'Problem',
        subHeading:
          'DeFi users Face Issues with Liquidity, User Experience, and Security',
        content: [
          'Many DeFi protocols struggle with liquidity fragmentation across various decentralized exchanges (DEXs), leading to low liquidity for certain assets and increased slippage for traders.',
          `While some existing DEXs suffer from poor user interfaces, complex trading mechanisms, and high gas fees due to congestion on the Ethereum network.`,
          `Lastly, non-modular and non-customizability creates barriers to users' needs.`
        ]
      },
      {
        heading: 'Solution',
        subHeading: 'AMM V4 + Hooks',
        content: [
          `KIM Exchange provides efficient and customizable liquidity solutions, such as concentrated liquidity pools, to enhance liquidity depth and reduce trading costs.  In addition, KIM Exchange improves user experience by offering a user-friendly interface, streamlined trading processes, and integration with Layer 2 scaling solutions.`,
          `KIM Exchange prioritizes robust smart contract development, comprehensive security audits, and the implementation of advanced security measures like multisig wallets to ensure the safety and integrity of its platform.`,
          `Hooks offer users on Kim Exchange a powerful toolkit for optimizing their trading and liquidity provision activities, empowering them to tailor their strategies, automate tasks, integrate external resources, and adapt to dynamic market environments with greater ease and effectiveness.`
        ]
      },
      {
        heading: 'Business Model',
        subHeading: 'Gas, Liquidity Provisions, and Yield Boosters',
        content: [
          `KIM Exchange expects an exponential growth in volume and liquidity provisions.  KIM Exchange prides itself in being the most incentivized dex on Mode with xKIM plugins, where KIMs growing community size validates the claim.  In addition, KIM Exchange will announce multiple partnerships and integrations, which will boost community size and volume.`
        ]
      },
      {
        heading: 'Market',
        subHeading: '$992B Total DEX Market Volume in the past 12 Months',
        content: [
          `As more and more people are resorting to DeFi, KIM Exchange will be at the forefront by offering experimental and customizable options for individual needs.  KIM Exchange experienced over 160,000 unique visitors over the past 30 days and this is just the beginning.`
        ]
      },
      {
        heading: 'Team',
        subHeading:
          'Guided by Experienced DeFi Architects and Established Pioneers',
        content: [
          `Tae is the Head of Ecosystem. Tae's a seasoned founder with a flair for operations and finance in both web2 and web3 realms. With a proven track record of managing projects on global exchanges and crafting effective APAC go-to-market strategies. Notably, Tae has held pivotal roles as the COO of Solve.Care, a pioneering force in healthcare blockchain, and MetaMUI, a trailblazer in the realm of CBDC.`,
          `Andrew is the Head of Marketing. Andrew has deep roots in both CeFi and DeFi, evident in his transformative work with Kim.exchange. His strategic foresight at LBank and a portfolio of CEXs, coupled with an innovative edge in DEX platforms, showcases his adeptness across the financial exchange spectrum. His acumen is further enriched by venture capital initiatives with Roark Fund, bringing a multifaceted perspective to growth and branding in the digital asset domain.`,
          `Oleh is the Lead Architect. Oleh is the Lead Architect. Oleh is an experienced software architect and web3 enthusiast with the background in Ethereum, Polkadot and NEAR Protocol. Oleh took leading roles in development of a large number of projects in web2 for such companies as Comcast and Ernst & Young as well as for web3 projects in NEAR Protocol(AstroDAO, NFT Auction) and was CTO at SocietalDAO(Polkadot parachain).`,
          `Oleg is a seasoned Senior Software Engineer specializing in the frontend development of cutting-edge decentralized applications (DApps) within the Web3 space. With 4 years of dedicated experience in blockchain technology and smart contracts, he excels in creating innovative and user-centric solutions that redefine digital interactions. His expertise and leadership in crafting intuitive frontends for DApps underscore his pivotal role in driving forward-thinking tech initiatives.`
        ]
      }
    ],
    description: `The native DEX of Mode, the liquidity hub of the Optimism Superchain.`,
    longDescription: `By leveraging Mode's unique approach to sequencer fee sharing, Kim is able to provide projects and communities with greater incentives.  A highly efficient and customizable protocol, allowing both builders and users to access deep, sustainable, and adaptable liquidity.`,
    ticker: 'KIM',
    network: 'MODE',
    tokenAddress: null,
    ownerAddress: null,
    saleAddress: null,
    socialURL: {
      website: 'https://www.kim.exchange/',
      twitter: 'https://twitter.com/kimprotocol',
      discord: 'https://discord.gg/kimprotocol',
      github: 'https://github.com/kim-protocol',
      telegram: null
    },
    saleDetails: {
      startsAt: 1714053600,
      endsAt: 1714053600,
      saleContractAddress:
        `0x1052FEab5c715D4f54aE1aAba5722523A71CaFCB` as `0x{string}`,
      saleTokenAddress: USDC_CONTRACT,
      maxRaiseAmount: 300000,
      ticketSize: 500
    }
  },
  // TODO Edit Details
  IRONCLAD: {
    id: 'IRONCLAD',

    enabled: true,
    name: 'Ironclad',
    bgColor: '#000',
    accentColor: '#f0d332',
    tagLine: `Presenting Ironclad - Mode's native credit layer.`,
    description: `Presenting Ironclad - Mode's native credit layer.`,
    longDescription: `Ironclad Finance is a decentralized non-custodial liquidity market protocol where users can participate as depositors or borrowers. Depositors provide liquidity to the market to earn a passive income, while borrowers are able to borrow in an overcollateralized (perpetually) or undercollateralized (one-block liquidity) fashion.`,
    details: [
      {
        heading: 'Quick Stats',
        content: [
          [
            '$135m market size',
            '$55.8m assets borrowed',
            '$20.15m assets available to borrow'
          ]
        ]
      },
      {
        heading: 'What is Ironclad?',
        content: [
          `Ironclad is Mode’s native credit layer, allowing users to get the best yield and the best loans on a single platform.`
        ]
      },
      {
        heading: 'What Makes Ironclad Special?',
        content: [
          `Ironclad utilizes a hybrid lending architecture which allows borrowers to draw loans from CDP collateral, establishing a fully decentralized interest-bearing stablecoin.`
        ]
      },
      {
        heading: '',
        content: [
          {
            type: 'video',
            src: 'https://www.youtube.com/embed/HjNmVNYAnfM?si=C8pYqfqw-ff5Fkeu?autoplay=0&showinfo=0&controls=1&modestbranding=0&rel=0&hd=1'
          }
        ]
      },
      {
        heading: 'Problem',
        content: [
          [
            'Well-managed DeFi protocols are largely inaccessible on new blockchain networks.',
            'DeFi interest rates are volatile & oftentimes don’t properly reflect risk / return.',
            'Decentralized stablecoins are largely incapable of competing with traditional analogs.'
          ]
        ]
      },
      {
        heading: 'Solution',
        content: [
          `Ironclad is backed by the industry’s largest DeFi engineering firm, whose research and technical expertise grants Ironclad best-in-class risk management, security, and interest-rate management.

          Read some of our team’s research here:
          IRM [https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4810469](https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4810469)
          Quant [https://tollydata.medium.com/](https://tollydata.medium.com/)
          Security [https://www.beirao.xyz/tags/blog](https://www.beirao.xyz/tags/blog)
          DeFi [https://medium.com/@zeroalpha](https://medium.com/@zeroalpha)

          Additionally, our team has developed iUSD - a stablecoin capable of generating its own real yield. This capability is achieved by allowing the stablecoin’s collateral backing to issue algorithmic loans to users as well as charge interest to depositors of interest-bearing collateral.`
        ]
      },
      {
        heading: 'ICL Staking',
        content: [
          `Stake your ICL tokens, gets tons of real yield and Mode governance power.

            Stake them longer, get even more real yield and Mode governance power.

            Our time-weighted staking system coupled with Ironclad’s high-performance lending architecture allows us to drive maximum value to users. Using iUSD, we can also delegate governance power from Mode tokens staked on our platform to ICL stakers, ensuring the ultimate staking experience.

            Read more about Ironclad staking here:
            [https://medium.com/byte-masons/defis-new-yield-primitive-52ec6ecfe2af](https://medium.com/byte-masons/defis-new-yield-primitive-52ec6ecfe2af)`
        ]
      },
      {
        heading: 'Team',
        content: [
          `Conclave, colloquially known as Byte Masons, is the largest DeFi engineering firm in the industry, with experience managing billions in TVL and building high VAR systems alongside collaborators like Optimism, Radiant Capital, Mantle, Mode, and Polygon.`
        ]
      }
    ],
    ticker: 'ICL',
    network: 'MODE',
    tokenAddress: null,
    ownerAddress: null,
    saleAddress: null,
    socialURL: {
      website: 'https://www.ironclad.finance/',
      twitter: 'https://twitter.com/ironcladfinance',
      discord: 'https://discord.gg/ta4yDWbxS7',
      github: null,
      telegram: null
    },
    saleDetails: {
      startsAt: 1717683300,
      endsAt: 1717683300,
      ticketSize: 500,
      saleContractAddress:
        `0xf5A7135651F65C9Ff03e365c33EEC821B2DFdf10` as `0x{string}`,
      saleTokenAddress: USDC_CONTRACT,
      maxRaiseAmount: 300000
    }
  }
};
