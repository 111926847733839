import { type ReactElement } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

type ButtonVariant = 'DEFAULT' | 'OUTLINE' | 'OUTLINE_NEGATIVE';

type Props = {
  text: string;
  onClick: () => void;
  variant?: ButtonVariant;
  icon?: ReactElement;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  justify?: string;
  paddingX?: string;
  paddingY?: string;
  textColor?: string;
  textColorOverride?: string;
  backgroundColor?: string;
  backgroundColorOverride?: string;
};

const theme: Record<
  ButtonVariant,
  {
    textColor: string;
    backgroundColor: string;
    border: string;
    borderColor: string;
  }
> = {
  DEFAULT: {
    textColor: 'white',
    backgroundColor: 'brandRed',
    border: '',
    borderColor: ''
  },
  OUTLINE: {
    textColor: 'brandRed',
    backgroundColor: 'transparent',
    border: '1px',
    borderColor: 'rgba(0,0,0,0.2)'
  },
  OUTLINE_NEGATIVE: {
    textColor: 'brandRed',
    backgroundColor: 'transparent',
    border: '1px',
    borderColor: 'rgba(255,255,255,0.3)'
  }
};

export const Button = ({
  text,
  onClick,
  variant = 'DEFAULT',
  icon,
  disabled = false,
  loading = false,
  width,
  justify,
  paddingX = '5',
  paddingY = '3',
  textColorOverride,
  backgroundColorOverride
}: Props) => {
  const { textColor, backgroundColor, border, borderColor } = theme[variant];

  return (
    <Box
      bg={disabled || loading ? 'gray' : backgroundColor}
      onClick={e => {
        e.stopPropagation();

        if (!disabled) {
          onClick();
        }
      }}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      as="button"
      textColor={textColorOverride || textColor}
      border={border}
      backgroundColor={backgroundColorOverride || backgroundColor}
      borderColor={borderColor}
      paddingX={paddingX}
      paddingY={paddingY}
      borderRadius="md"
      fontSize="md"
      opacity={disabled || loading ? 0.4 : 1}
      justifyContent={justify}
      display={'flex'}
      flexDir="row"
      alignItems="center"
      gap={2}
      fontWeight="semibold"
      disabled={disabled || loading}
      width={width}
    >
      <Text>{loading ? '...' : text}</Text>
      {icon && <Flex mb={0.5}>{icon}</Flex>}
    </Box>
  );
};
